<template>
    <div class="top">
        <img class="bg-top" src="../assets/images/situation/bg-top.png" ondragstart="return false;"/>
        <span class="top-title" @dblclick="changeScreen()">智慧村务大数据可视化分析系统</span>
        <div class="top-left">
            <div class="left-time">{{time}}</div>
            <div class="left-date">
                <span class="date-d">{{date}}</span><br/>
                <span>{{week}}</span>
            </div>
        </div>
        <div class="top-right">
            <div class="right-num">32</div>
            <img class="right-icon" src="../assets/images/top-weather.png" ondragstart="return false;"/>
            <div class="right-info">
                <span class="info-w">26°~32°</span><br/>
                <span>多云转晴</span>
            </div>
            <img class="right-logout" src="../assets/images/logout.png" @click="logout()" ondragstart="return false;"/>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            isFullScreen: false,
            clock: null,
            date: '----/--/--',
            time: '--:--:--',
            week: '--'
        }
    },
    mounted(){
        this.createClock()
    },
    destroyed(){
        clearInterval(this.clock)
    },
    methods:{
        /* 切换屏幕 */
        changeScreen(){
            if(this.isFullScreen){
                this.$Util.exitFullscreen();
            }else{
                this.$Util.toFullScreen();
            }
            this.isFullScreen = !this.isFullScreen
            setTimeout(() =>{
                this.$emit('screenChange');
            },500)
        },
        /* 创建时间 */
        createClock(){
            this.clock = setInterval(() =>{
                this.date = this.Moment(new Date()).format('YYYY-MM-DD')
                this.time = this.Moment(new Date()).format('HH:mm:ss')
                let day = this.Moment(new Date()).day()
                switch (day) {
                    case 1:
                        this.week = '周一'
                        break;
                    case 2:
                        this.week = '周二'
                        break;
                    case 3:
                        this.week = '周三'
                        break;
                    case 4:
                        this.week = '周四'
                        break;
                    case 5:
                        this.week = '周五'
                        break;
                    case 6:
                        this.week = '周六'
                        break;
                    case 0:
                        this.week = '周日'
                        break;
                    default:
                        break;
                }
            },1000)
        },
        /* 退出登录 */
        logout(){
            localStorage.removeItem('token');
            this.$router.push('/login');
        },
    }
}
</script>

<style lang="less" scoped>
    .top{
        width: 100%;
        height: 90px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        z-index: 100;
        .bg-top{
            width: 100%;
            max-height: 90px;
            object-fit: fill;
            position: absolute;
            top: 0;
            z-index: 0;
        }
        .top-title{
            font-size: 34px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            text-shadow: 0px 4px 5px #171313;
            position: absolute;
            top: 6px;
            z-index: 1;
        }
        .top-left{
            position: absolute;
            left: 18px;
            top: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #FFFFFF;
            opacity: 0.5;
            .left-time{
                font-size: 34px;
                color: #FFFFFF;
                font-weight: 100;
            }
            .left-date{
                margin-left: 8px;
                line-height: 16px;
                font-size: 13px;
                font-weight: 400;
                .date-d{
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
        .top-right{
            position: absolute;
            right: 18px;
            top: 6px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            color: #FFFFFF;
            .right-num{
                font-size: 32px;
                font-family: Inter;
                font-weight: 200;
                color: #FFFFFF;
                opacity: 0.5;
            }
            .right-icon{
                width: 34px;
                height: 34px;
                object-fit: contain;
                margin: 0px 8px;
                opacity: 0.5;
            }
            .right-info{
                line-height: 16px;
                font-size: 13px;
                opacity: 0.5;
                .info-w{
                    font-size: 12px;
                }
            }
            .right-logout{
                width: 24px;
                height: 24px;
                margin-left: 10px;
                object-fit: contain;
                cursor: pointer;
                transition: all 0.2s;
                -webkit-transition: all 0.2s;
                opacity: 0.5;
                &:hover{
                    opacity: 0.7 !important;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                }
            }
        }
    }
</style>