<template>
    <div class="sjzl">
        <div class="top">
            <span>凤台卫生服务中心</span>
            <img class="top-close" @click="closeModel" src="../../assets/images/close-icon.png" ondragstart="return false;"/>
        </div>
        <div class="info">
            <div class="head">
                <img class="head-img" src="../../assets/images/situation/user-2.jpg" ondragstart="return false;"/>
                <div class="head-name">院长</div>
            </div>
            <div class="info-a">
                <span>姓名：柳叶新</span>
                <span>性别：男</span>
                <span>民族：汉</span>
            </div>
            <div class="info-b">
                <span>整治面貌：党员</span>
                <span>出生时间：1971-08-07</span>
                <span>联系电话：156****8163</span>
            </div>
        </div>
        <div class="family" style="opacity:0.6">
            <div class="family-relation">科室</div>
            <div class="family-name">姓名</div>
            <div class="family-sex">性别</div>
            <div class="family-nation">民族</div>
            <div class="family-born">出生日期</div>
            <div class="family-tel">联系电话</div>
        </div>
        <div class="family family-item" v-for="(item,index) in list" :key="index">
            <div class="family-relation">{{item.relation}}</div>
            <div class="family-name">{{item.name}}</div>
            <div class="family-sex">{{item.sex}}</div>
            <div class="family-nation">{{item.nation}}</div>
            <div class="family-born">{{item.born}}</div>
            <div class="family-tel">{{item.tel}}</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            list: [
                {relation:'门诊', name:'江宇', sex:'男', nation:'汉', born:'1982-01-23', tel:'177****3212'},
                {relation:'急诊', name:'何叶凯', sex:'男', nation:'汉', born:'1985-08-02', tel:'131****8832'},
                {relation:'康复', name:'陈欣', sex:'女', nation:'汉', born:'1985-08-02', tel:'131****8832'},
            ]
        }
    },
    methods:{
        closeModel(){
            this.$emit('close')
        },
    }
}
</script>

<style lang="less" scoped>
    .sjzl{
        width: 350px;
        height: 255px;
        background: rgba(0, 0, 0, 0.8);
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(232, 250, 255, 0.8);
        .top{
            width: 100%;
            height: 32px;
            background: linear-gradient(to right, #239502, rgba(35, 149, 2, 0.1));
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0px 12px 0px 15px;
            box-sizing: border-box;
            line-height: 32px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .top-close{
                width: 18px;
                height: 18px;
                object-fit: contain;
                cursor: pointer;
                &:hover{
                    opacity: 0.6;
                }
            }
        }
        .info{
            width: calc(100% - 28px);
            margin: 12px auto 0px auto;
            line-height: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            .head{
                width: 66px;
                height: 77px;
                position: relative;
                .head-img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                }
                .head-name{
                    width: 100%;
                    height: 18px;
                    background-color: rgba(0, 0, 0, 0.5);
                    text-align: center;
                    line-height: 18px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                }
            }
            .info-a{
                width: 80px;
                height: 90%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                margin-left: 15px;
            }
            .info-b{
                width: 140px;
                height: 90%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                margin-left: 15px;
            }
        }
        .family-item{
            background-color: rgba(47,47,47,0.5);
        }
        .family{
            width: calc(100% - 28px);
            height: 27px;
            margin: 4px auto 0px auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .family-relation{
                width: 37px;
                padding-left: 10px;
            }
            .family-name{
                width: 52px;
            }
            .family-sex{
                width: 32px;
            }
            .family-nation{
                width: 32px;
            }
            .family-born{
                width: 80px;
            }
            .family-tel{
                width: 80px;
            }
        }
    }
</style>