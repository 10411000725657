<template>
    <div class="dybb">
        <div class="top">
            <span>{{userInfo.name}}</span>
            <img class="top-close" @click="closeModel" src="../../assets/images/close-icon.png" ondragstart="return false;"/>
        </div>
        <div class="info">
            <img v-if="userInfo.name=='唐成保'" class="info-img" src="../../assets/images/situation/user-2.jpg" ondragstart="return false;"/>
            <img v-else-if="userInfo.name=='许晴房'" class="info-img" src="../../assets/images/situation/user-3.jpg" ondragstart="return false;"/>
            <img v-else-if="userInfo.name=='王淑芬'" class="info-img" src="../../assets/images/situation/user-4.jpg" ondragstart="return false;"/>
            <div class="info-detail">
                <span>党员学历：初中</span>
                <span>入党时间：1978-06-09</span>
                <span>联系电话：192****7848</span>
                <span>出生日期：1967-08-26</span>
                <span>居住地址：乡村自然村1253号</span>
            </div>
        </div>
        <div class="msgs msg-head">
            <div class="msg-a">序号</div>
            <div class="msg-b">包干户</div>
            <div class="msg-c">家庭成员</div>
        </div>
        <div class="msgs" v-for="(item,index) in groupList" :key="index">
            <div class="msg-a">{{item.id}}</div>
            <div class="msg-b">{{item.name}}</div>
            <div class="msg-c">{{item.family}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        userInfo: {
            type: Object,
            default: ()=>{
                return {
                    name: '唐成保'
                }
            }
        }
    },
    data(){
        return{
            groupList: [
                {id:'1', name:'夏友祥', family:'6人'},
                {id:'2', name:'陶天宁', family:'6人'},
                {id:'3', name:'杨克林', family:'5人'},
                {id:'4', name:'唐思发', family:'2人'},
            ]
        }
    },
    methods:{
        closeModel(){
            this.$emit('close')
        }
    }
}
</script>

<style lang="less" scoped>
    .dybb{
        width: 298px;
        padding-bottom: 12px;
        background-color: rgba(0, 0, 0, 0.8);
        .top{
            width: 100%;
            height: 32px;
            background: linear-gradient(to right, #A11E20, rgba(161, 30, 32, 0.1));
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0px 12px 0px 15px;
            box-sizing: border-box;
            line-height: 32px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .top-close{
                width: 18px;
                height: 18px;
                object-fit: contain;
                cursor: pointer;
                &:hover{
                    opacity: 0.6;
                }
            }
        }
        .info{
            width: calc(100% - 24px);
            height: 128px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 12px auto 0px auto;
            .info-img{
                width: 100px;
                height: 128px;
                object-fit: cover;
            }
            .info-detail{
                width: calc(100% - 112px);
                margin-left: 12px;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #E8FAFF;
                opacity: 0.8;
            }
        }
        .msg-head{
            opacity: 0.5;
            background: transparent !important;
        }
        .msgs{
            width: calc(100% - 24px);
            height: 28px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            margin: 4px auto 0px auto;
            background: rgba(79,15,15,0.5000);
            .msg-a{
                width: 66px;
                padding-left: 12px;
            }
            .msg-b{
                width: 142px;
            }
            .msg-c{
                width: 64px;
            }
        }
    }
</style>