<template>
    <div class="top-menu">
        <div class="menu-item" @click="chooseMenu('事件总览')">
            <div class="item-box">
                <img v-if="activeMenu=='事件总览'" class="item-icon" src="../assets/images/situation/map-cricle-hl.png" ondragstart="return false;"/>
                <img v-else class="item-icon" src="../assets/images/situation/map-cricle.png" ondragstart="return false;"/>
                <img class="item-mini" src="../assets/images/safe/icon-mini-sjzl.png" ondragstart="return false;"/>
            </div>
            <div class="item-lab" :class="activeMenu=='事件总览'?'lab-active':'lab-dark'">事件总览</div>
        </div>
        <div class="menu-item" @click="chooseMenu('水域安全')">
            <div class="item-box">
                <img v-if="activeMenu=='水域安全'" class="item-icon" src="../assets/images/situation/map-cricle-hl.png" ondragstart="return false;"/>
                <img v-else class="item-icon" src="../assets/images/situation/map-cricle.png" ondragstart="return false;"/>
                <img class="item-mini" src="../assets/images/safe/icon-mini-syaq.png" ondragstart="return false;"/>
            </div>
            <div class="item-lab" :class="activeMenu=='水域安全'?'lab-active':'lab-dark'">水域安全</div>
        </div>
        <div class="menu-item" @click="chooseMenu('摄像头')">
            <div class="item-box">
                <img v-if="activeMenu=='摄像头'" class="item-icon" src="../assets/images/situation/map-cricle-hl.png" ondragstart="return false;"/>
                <img v-else class="item-icon" src="../assets/images/situation/map-cricle.png" ondragstart="return false;"/>
                <img class="item-mini" src="../assets/images/safe/icon-mini-sxt.png" ondragstart="return false;"/>
            </div>
            <div class="item-lab" :class="activeMenu=='摄像头'?'lab-active':'lab-dark'">摄像头</div>
        </div>
        <div class="menu-item" @click="chooseMenu('重点人员')">
            <div class="item-box">
                <img v-if="activeMenu=='重点人员'" class="item-icon" src="../assets/images/situation/map-cricle-hl.png" ondragstart="return false;"/>
                <img v-else class="item-icon" src="../assets/images/situation/map-cricle.png" ondragstart="return false;"/>
                <img class="item-mini" src="../assets/images/safe/icon-mini-zdry.png" ondragstart="return false;"/>
            </div>
            <div class="item-lab" :class="activeMenu=='重点人员'?'lab-active':'lab-dark'">重点人员</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            activeMenu: '事件总览'
        }
    },
    methods:{
        chooseMenu(val){
            this.activeMenu = val
            this.$emit('chooseMenu',val)
        },
    }
}
</script>

<style lang="less" scoped>
    .top-menu{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 90px;
        z-index: 100;
        .menu-item{
            width: 120px;
            height: 84px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            &:hover{
                .lab-dark{
                    background: rgba(28,68,1,0.6000);
                    font-weight: 500;
                    transition: all 0.15s;
                    -webkit-transition: all 0.15s;
                }
            }
            .item-box{
                width: 54px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                .item-icon{
                    width: 54px;
                    height: 54px;
                    object-fit: contain;
                }
                .item-mini{
                    width: 16px;
                    height: 16px;
                    object-fit: contain;
                    position: absolute;
                    z-index: 1;
                }
            }
            .item-lab{
                width: 85px;
                height: 26px;
                border-radius: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
            .lab-dark{
                background: rgba(0,0,0,0.4000);
                transition: all 0.15s;
                -webkit-transition: all 0.15s;
            }
            .lab-active{
                background: rgba(28,68,1,0.6000);
                font-weight: 500;
                transition: all 0.15s;
                -webkit-transition: all 0.15s;
            }
        }
    }
</style>