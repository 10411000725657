<template>
    <div class="videos">
        <div class="top">
            <span>{{videosInfo.name}}</span>
            <img class="top-close" @click="closeModel" src="../../assets/images/close-icon.png" ondragstart="return false;"/>
        </div>
        <div id="cameraVideo" class="video-img" v-loading="VideoLoading" element-loading-background="rgba(0, 0, 0, 0.5)"></div>
    </div>
</template>

<script>
export default {
    props:{
        ID: {
            type: String,
            default: '5'
        },
        videosInfo:{
            type: Object,
            default: ()=>{
                return{
                    name: '--'
                }
            }
        }
    },
    data(){
        return{
            VideoLoading: true
        }
    },
    mounted(){
        this.createVideo();
        this.VideoLoading = true
        this.$apiRequest.queryVideoUrl({id:this.ID}).then(res =>{
            this.playVideo(res.data.url)
        })
    },
    beforeDestroy(){
        if(this.myPlugin){
            this.stopVideo();
        }
    },
    beforeUnmount(){
        if(this.myPlugin){
            this.stopVideo();
        }
    },
    methods:{
        closeModel(){
            this.$emit('close')
        },
        /* *************************** HK视频相关 *************************** */
        /* 实例化视频 */
        createVideo(){
            var _this = this
            this.myPlugin = new JSPlugin({
                szId: 'cameraVideo',
                szBasePath: '../../../../static',
                oStyle: {
                    border: "transparent",
                    borderSelect: "transparent",
                    background: "transparent"
                }
            })
            this.myPlugin.JS_SetWindowControlCallback({
                pluginErrorHandler: function (index, iErrorCode, oError) {
                    console.log('插件错误回调', iErrorCode,oError)
                },
                firstFrameDisplay: function (index, iWidth, iHeight) {
                    _this.VideoLoading = false
                    console.log('首帧显示回调', index)
                },
                performanceLack: function () {
                    console.log('性能不足回调')
                },
                StreamEnd: function (index) {
                    console.log('回放结束回调,返回对应测窗口id', index)
                }
            });
        },
        /* 播放实时视频 */
        playVideo(_url){
            var _this = this
            this.myPlugin.JS_Play(_url,{playURL:_url,mode: 1}, 0).then(() => {
                    console.info('JS_Play succcess:');
                },(err) => {
                    _this.$message.warning('播放失败，请检查网络或设备是否正常。')
                }
            );
        },
        /* 停止播放 */
        stopVideo(){
            this.myPlugin.JS_StopRealPlayAll().then(() => {
                console.info('JS_StopRealPlayAll success');
            },(err) => {
                console.info('JS_StopRealPlayAll failed:', err);
            });
        },
    }
}
</script>

<style lang="less" scoped>
    .videos{
        width: 640px;
        height: 400px;
        background-color: rgba(0, 0, 0, 0.6);
        .top{
            width: 100%;
            height: 40px;
            background: linear-gradient(to right, #0782FB, rgba(7, 130, 251, 0.1));
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0px 12px 0px 15px;
            box-sizing: border-box;
            line-height: 32px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .top-close{
                width: 18px;
                height: 18px;
                object-fit: contain;
                cursor: pointer;
                &:hover{
                    opacity: 0.6;
                }
            }
        }
        .video-img{
            width: 100%;
            height: 360px;
            object-fit: cover;
        }
        video::-webkit-media-controls-enclosure{ 
            display: none;
        }
    }
</style>