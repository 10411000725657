<template>
    <div class="bottom-situation">
        <div class="group" v-if="activeBtn=='村貌分布'">
            <div class="group-item" v-for="(item,index) in list" :key="index">
                <div class="item-dot" :style="'background-color:' + item.color"></div>
                <span class="item-lab">{{item.name}}</span>
            </div>
        </div>
        <div :class="{'btn-light':activeBtn=='POI','btn-dark':activeBtn!='POI'}" @click="chooseBtn('POI')">
            <img v-if="activeBtn=='POI'" class="img" src="../assets/images/cesium/bot-group-hl.png" ondragstart="return false;"/>
            <img v-else class="img" src="../assets/images/cesium/bot-group-dark.png" ondragstart="return false;"/>
            <span>POI</span>
        </div>
        <div :class="{'btn-light':activeBtn=='村貌分布','btn-dark':activeBtn!='村貌分布'}" @click="chooseBtn('村貌分布')">
            <img v-if="activeBtn=='村貌分布'" class="img" src="../assets/images/cesium/bot-group-hl.png" ondragstart="return false;"/>
            <img v-else class="img" src="../assets/images/cesium/bot-group-dark.png" ondragstart="return false;"/>
            <span>村貌分布</span>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            activeBtn: 'POI',
            list: [
                {name:'耕地', color:'#FECB28'},
                {name:'林地', color:'#30C2FF'},
                {name:'水域', color:'#016DFF'},
                {name:'住宅用地', color:'#0054C5'},
                {name:'教育用地', color:'#30FFD6'},
                {name:'工业用地', color:'#0CB424'},
            ]
        }
    },
    methods:{
        chooseBtn(val){
            this.activeBtn = val
            this.$emit('chooseBottomBtn',val)
        },
    }
}
</script>

<style lang="less" scoped>
    .bottom-situation{
        width: 106px;
        height: 300px;
        position: absolute;
        left: 430px;
        bottom: 80px;
        z-index: 100;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        .btn-dark{
            width: 106px;
            height: 37px;
            background: linear-gradient(180deg, #002B55 0%, #00315A 100%);
            opacity: 0.8;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 500;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: 12px;
            cursor: pointer;
            transition: all 0.15s;
            -webkit-transition: all 0.15s;
            &:hover{
                opacity: 0.7;
                transition: all 0.15s;
                -webkit-transition: all 0.15s;
            }
            .img{
                width: 100%;
                height: 100%;
                object-fit: fill;
                position: absolute;
                z-index: -1;
            }
        }
        .btn-light{
            width: 106px;
            height: 37px;
            background: linear-gradient(180deg, #1F8804 0%, #1F8804 100%);
            opacity: 0.8;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 500;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: 12px;
            cursor: pointer;
            transition: all 0.15s;
            -webkit-transition: all 0.15s;
            &:hover{
                opacity: 0.7;
                transition: all 0.15s;
                -webkit-transition: all 0.15s;
            }
            .img{
                width: 100%;
                height: 100%;
                object-fit: fill;
                position: absolute;
                z-index: -1;
            }
        }
        .group{
            width: 106px;
            height: 192px;
            background-color: rgba(0,0,0,0.6);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            .group-item{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 6px 0px;
                white-space: nowrap;
                .item-dot{
                    width: 10px;
                    height: 10px;
                    margin: 0px 12px 0px 16px;
                }
                .item-lab{
                    width: 70px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #C6F3FF;
                }
            }
            animation: bottomIn 0.1s linear;
            @keyframes bottomIn {
                0%{
                    width: 0px;
                    opacity: 0;
                }
                100%{
                    width: 106px;
                    opacity: 1;
                }
            }
            @-webkit-keyframes bottomIn {
                0%{
                    width: 0px;
                    opacity: 0;
                }
                100%{
                    width: 106px;
                    opacity: 1;
                }
            }
        }
    }
</style>