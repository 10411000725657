<template>
    <div class="mask">
        <el-progress class="mask-progress" :text-inside="true" :stroke-width="20" :percentage="percentage" status="success"></el-progress>
        <!-- <img class="mask-bg" src="@/assets/images/home-bg.png" ondragstart="return false;"/> -->
        <video class="mask-bg" autoplay loop :muted="true">
            <source src="https://lywl-park.oss-cn-shanghai.aliyuncs.com/wdp-village/earth.mp4" type="video/mp4"/>
        </video>
        <div class="mask-lab">智慧村务大数据可视化分析系统正在启动，请耐心等待</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            percentage: 0
        }
    },
    mounted(){
        var inter = setInterval(() =>{
            if(this.percentage < 100){
                this.percentage += 4
            }else{
                clearInterval(inter)
            }
        },200)
    }
}
</script>

<style lang="less" scoped>
    .mask{
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        position: absolute;
        z-index: 99;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .mask-progress{
            width: 400px;
            height: 20px;
            margin-bottom: 20px;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);
            border-radius: 10px;
            /deep/ .el-progress-bar__outer{
                background-color: rgba(255, 255, 255, 0.1);
            }
            /deep/ .el-progress-bar__innerText{
                font-size: 12px;
                font-weight: bold;
            }
        }
        .mask-bg{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            z-index: -1;
        }
        .mask-lab{
            font-family: cursive;
            font-size: 22px;
            color: #CFF7FFFF;
            text-shadow: 0px 4px 5px #171313;
            width: 540px;
            white-space: nowrap;
            overflow: hidden;
            animation: typing 3s;
        }
        @keyframes typing {
            from { width: 0 }
        }
        @-webkit-keyframes typing {
            from { width: 0 }
        }
    }
</style>