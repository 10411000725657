let CesiumData = {}

// CesiumData.TotalIDs = [
//     'POI#01', 'POI#02', 'POI#03', 'POI#04', '党员包保#01', '党员包保#02', '党员包保#03', '事件类型#01', '事件类型#02', '事件类型#03', '事件类型#04',
//     '水域监控#01', '水域监控#02', '水域监控#03', '水域监控#04', '高清监控#01', '高清监控#02', '高清监控#03', '高清监控#04', '高清监控#05', '高清监控#06',
//     '高清监控#07', '高清监控#08', '村貌分布#01', '村貌分布#02', '村貌分布#03', '村貌分布#04', '村貌分布#05', '村貌分布#06', '村貌分布#07',
//     'USERTIME#01', 'USERTIME#02', 'USERTIME#03', 'USERTIME#04'
// ]
CesiumData.TotalIDs = [
    'POI#01', 'POI#02', 'POI#03', 'POI#04', '党员包保#01', '党员包保#02', '党员包保#03', '事件类型#01', '事件类型#02', '事件类型#03', '事件类型#04',
    '水域监控#01', '水域监控#02', '高清监控#01', '高清监控#02',
     '村貌分布#01', '村貌分布#02', '村貌分布#03', '村貌分布#04', '村貌分布#05', '村貌分布#06', '村貌分布#07',
    'USERTIME#01', 'USERTIME#02', 'USERTIME#03', 'USERTIME#04',
    'POI#mz01','POI#mz10','POI#mz17','POI#mz24','POI#mz27', 'POI#VIDEO#01', 'POI#VIDEO#02'
]

/* 视角数据 */
CesiumData.HomeView = [
    // {name:'村落一', lon: 118.1838499, lat: 31.8154521, alt: 160, roll: 0, heading: 90, pitch: -24},
    {name:'村落一', lon: 118.1912618, lat: 31.8239173, alt: 500, roll: 360, heading: 180, pitch: -30},


    {name:'村落二', lon: 118.1969017, lat: 31.8138609, alt: 100, roll: 0, heading: 331.50, pitch: -15.27},
]

/* 点位数据 POI */
CesiumData.PointDataPOI = [
    {id:'POI#01', etype:'point', status:1, type:5, img:'icon-build01', Cartesian3:{x: -2562448.37342992,y: 4781518.44676888,z: 3343095.378629549}, text:'建筑物-1'},
    {id:'POI#02', etype:'point', status:1, type:5, img:'icon-build02', Cartesian3:{x: -2562499.0729851592,y: 4781464.941731327,z: 3343132.4617418996}, text:'建筑物-2'},
    {id:'POI#04', etype:'point', status:1, type:5, img:'icon-build04', Cartesian3:{x: -2562563.8418002804,y: 4781467.891559984,z: 3343077.450653339}, text:'建筑物-3'},
    {id:'POI#03', etype:'point', status:1, type:5, img:'icon-build03', Cartesian3:{x: -2562597.024983477,y: 4781577.066987583,z: 3342896.0631982908}, text:'建筑物-4'},
]

CesiumData.PointDataMZ = [
    {id:'POI#mz01', etype:'point', status:1, type:5, img:'icon-mz01', Cartesian3:{x: -2562523.0353663997,y: 4781496.752959027,z: 3343069.951684174}, text:'民宅01'},
    {id:'POI#mz10', etype:'point', status:1, type:5, img:'icon-mz10', Cartesian3:{x: -2562428.3652028046,y: 4781552.915421915,z: 3343057.1905817427}, text:'民宅10'},
    {id:'POI#mz17', etype:'point', status:1, type:5, img:'icon-mz17', Cartesian3:{x: -2562506.283047939,y: 4781536.417510714,z: 3343026.5751079996}, text:'民宅17'},
    {id:'POI#mz24', etype:'point', status:1, type:5, img:'icon-mz24', Cartesian3:{x: -2562508.966343202,y: 4781570.072465387,z: 3342976.6153585557}, text:'民宅24'},
    {id:'POI#mz27', etype:'point', status:1, type:5, img:'icon-mz27', Cartesian3:{x: -2562479.54934579,y: 4781646.131725892,z: 3342899.0102512804}, text:'民宅27'},
]

CesiumData.PointDataVIDEO = [
    {id:'POI#VIDEO#01', etype:'point', status:1, type:5, img:'icon-gqjk01', Cartesian3:{x: -2562366.1329139057,y: 4781549.08179297,z: 3343114.864985039}, text:'高清监控-1'},
    {id:'POI#VIDEO#02', etype:'point', status:1, type:5, img:'icon-gqjk02', Cartesian3:{x: -2562507.746233021,y: 4781583.090921679,z: 3342958.2563132364}, text:'高清监控-2'}
]

// {id:'POI#01', etype:'point', status:1, type:5, img:'tag-icon', lon:118.174181, lat:31.822799, height:20, Cartesian3:{x: -2562597.024983477,y: 4781577.066987583,z: 3342896.0631982908}, text:'建筑物-4'},
// {id:'POI#02', etype:'point', status:1, type:5, img:'tag-icon', lon:118.169454, lat:31.824877, height:20, Cartesian3:{x: -2562597.024983477,y: 4781577.066987583,z: 3342896.0631982908}, text:'建筑物-4'},
// {id:'POI#03', etype:'point', status:1, type:5, img:'tag-icon', lon:118.164292, lat:31.820099, height:20, Cartesian3:{x: -2562597.024983477,y: 4781577.066987583,z: 3342896.0631982908}, text:'建筑物-4'},
// {id:'POI#04', etype:'point', status:1, type:5, img:'tag-icon', lon:118.160425, lat:31.818476, height:20, Cartesian3:{x: -2562597.024983477,y: 4781577.066987583,z: 3342896.0631982908}, text:'建筑物-4'},
// {id:'POI#05', etype:'point', status:1, type:5, img:'tag-icon', lon:118.148625, lat:31.806802, height:20, Cartesian3:{x: -2562597.024983477,y: 4781577.066987583,z: 3342896.0631982908}, text:'建筑物-4'},

/* 二号村 定位 */
CesiumData.SecondPointDataPOI = [
    {id:'POI#01', etype:'point', status:1, type:5, img:'icon-build01', Cartesian3:{x: -2563100.6254958673,y: 4781171.920542771,z: 3343097.932900425}, text:'建筑物-1'},
    {id:'POI#02', etype:'point', status:1, type:5, img:'icon-build02', Cartesian3:{x: -2563141.133917427,y: 4781134.4816375775,z: 3343114.660525709}, text:'建筑物-2'},
    {id:'POI#03', etype:'point', status:1, type:5, img:'icon-build03', Cartesian3:{x: -2563153.327784552,y: 4781114.57296174,z: 3343133.827252892}, text:'建筑物-3'},
    {id:'POI#04', etype:'point', status:1, type:5, img:'icon-build04', Cartesian3:{x: -2563041.881382164,y: 4781138.605129224,z: 3343203.9580142936}, text:'建筑物-4'},
]

/* 点位数据 党员包保 */
CesiumData.PointDataDYBB = [
    {id:'党员包保#01', etype:'point', status:1, type:5, img:'icon-safe01', Cartesian3:{x: -2562529.2566650608,y: 4781486.126825252,z: 3343080.2688679774}, text:'唐成保'},
    {id:'党员包保#02', etype:'point', status:1, type:5, img:'icon-safe02', Cartesian3:{x: -2562434.4678006046,y: 4781516.007660889,z: 3343106.772260119}, text:'许晴房'},
    {id:'党员包保#03', etype:'point', status:1, type:5, img:'icon-safe03', Cartesian3:{x: -2562493.5258864113,y: 4781543.10235702,z: 3343021.58540157}, text:'王淑芬'},
]

/* 点位数据 事件类型 */
CesiumData.PointDataEvent = [
    {id:'事件类型#01', etype:'point', status:1, type:5, img:'icon-fwwj', Cartesian3:{x: -2562410.8982084584,y: 4781508.962970362,z: 3343134.8673915323}, text:'事件类型-1'},
    {id:'事件类型#02', etype:'point', status:1, type:5, img:'icon-hjzz', Cartesian3:{x: -2562453.2189709335,y: 4781533.755746124,z: 3343054.649746009}, text:'事件类型-2'},
    {id:'事件类型#03', etype:'point', status:1, type:5, img:'icon-llmd', Cartesian3:{x: -2562504.974978165,y: 4781556.6231572265,z: 3342995.857520931}, text:'事件类型-3'},
    {id:'事件类型#04', etype:'point', status:1, type:5, img:'icon-xfsj', Cartesian3:{x: -2562540.6496412377,y: 4781508.532318312,z: 3343033.754157909}, text:'事件类型-4'},
]

/* 点位数据 水域监控 */
CesiumData.PointDataRiver = [
    {id:'水域监控#01', etype:'point', status:1, type:5, img:'icon-syjk01', Cartesian3:{x: -2562479.702330099,y: 4781513.650246253,z: 3343072.8824697686}, text:'水域监控-1'},
    // {id:'水域监控#02', etype:'point', status:1, type:5, img:'icon-syjk02', Cartesian3:{x: -2562510.3005611915,y: 4781517.595661604,z: 3343044.0868489905}, text:'水域监控-2'},
    {id:'水域监控#02', etype:'point', status:1, type:5, img:'icon-syjk02', Cartesian3:{x: -2562517.5219354,y: 4781502.07676471,z: 3343062.6597593175}, text:'水域监控-3'},
    // {id:'水域监控#04', etype:'point', status:1, type:5, img:'icon-syjk04', Cartesian3:{x: -2562505.657517078,y: 4781486.78698318,z: 3343089.4849148747}, text:'水域监控-4'},
]

/* 点位数据 高清监控 */
CesiumData.PointDataCamera = [
    // {id:'高清监控#01', etype:'point', status:1, type:5, img:'icon-gqjk01', Cartesian3:{x: -2562366.1329139057,y: 4781549.08179297,z: 3343114.864985039}, text:'高清监控-1'},
    // {id:'高清监控#02', etype:'point', status:1, type:5, img:'icon-gqjk02', Cartesian3:{x: -2562442.378355579,y: 4781527.73741535,z: 3343085.783162181}, text:'高清监控-2'},
    // {id:'高清监控#03', etype:'point', status:1, type:5, img:'icon-gqjk03', Cartesian3:{x: -2562528.5375617123,y: 4781529.888370022,z: 3343020.9692254006}, text:'高清监控-3'},
    // {id:'高清监控#04', etype:'point', status:1, type:5, img:'icon-gqjk04', Cartesian3:{x: -2562592.5635629115,y: 4781543.345699915,z: 3342956.299548871}, text:'高清监控-4'},
    // {id:'高清监控#05', etype:'point', status:1, type:5, img:'icon-gqjk05', Cartesian3:{x: -2562643.603449074,y: 4781553.674597889,z: 3342886.876286014}, text:'高清监控-5'},
    // {id:'高清监控#06', etype:'point', status:1, type:5, img:'icon-gqjk06', Cartesian3:{x: -2562481.446368802,y: 4781439.33185198,z: 3343177.298281318}, text:'高清监控-6'},
    // {id:'高清监控#07', etype:'point', status:1, type:5, img:'icon-gqjk07', Cartesian3:{x: -2562507.746233021,y: 4781583.090921679,z: 3342958.2563132364}, text:'高清监控-7'},
    // {id:'高清监控#08', etype:'point', status:1, type:5, img:'icon-gqjk08', Cartesian3:{x: -2562478.4756978666,y: 4781549.781796912,z: 3343020.3575848737}, text:'高清监控-8'},
    {id:'高清监控#01', etype:'point', status:1, type:5, img:'icon-gqjk01', Cartesian3:{x: -2562366.1329139057,y: 4781549.08179297,z: 3343114.864985039}, text:'高清监控-1'},
    {id:'高清监控#02', etype:'point', status:1, type:5, img:'icon-gqjk02', Cartesian3:{x: -2562507.746233021,y: 4781583.090921679,z: 3342958.2563132364}, text:'高清监控-2'}
]

// 区域数据
CesiumData.AreaDataUse = [
    {id:'村貌分布#01', status:1, etype:'area', type:6, hierarchy:[
            {x: -2562702.795514438,y: 4781372.51553677,z: 3343094.2028786093},
            {x: -2562712.5818487797,y: 4781343.625873337,z: 3343135.7075045966},
            {x: -2562743.22928147,y: 4781271.35552906,z: 3343216.3564917026},
            {x: -2562722.2506705667,y: 4781229.072758217,z: 3343294.715664525},
            {x: -2562649.4113013907,y: 4781262.169522611,z: 3343303.2680232846},
            {x: -2562480.933433084,y: 4781364.837030541,z: 3343277.4266681676},
            {x: -2562613.886831748,y: 4781408.483939929,z: 3343107.0613604793}
        ], extrudedHeight:4, color:'#FECB28', bordercolor:'#FECB28', text:'耕地'},
    {id:'村貌分布#02', status:1, etype:'area', type:6, hierarchy:[
            {x: -2562711.056814229,y: 4781366.645216568,z: 3343098.158900037},
            {x: -2562748.384442752,y: 4781272.5083547365,z: 3343211.1031919145},
            {x: -2562722.6309375823,y: 4781219.35934718,z: 3343309.011465421},
            {x: -2562846.7385059493,y: 4781150.194323473,z: 3343326.1568049607},
            {x: -2562850.075215286,y: 4781187.042250992,z: 3343270.811652303},
            {x: -2562831.212860152,y: 4781218.397385862,z: 3343233.2660218854},
            {x: -2562783.9327727268,y: 4781280.124503491,z: 3343179.381729736},
            {x: -2562783.1086107735,y: 4781302.219717371,z: 3343146.965903097},
            {x: -2562792.3354243943,y: 4781313.691527667,z: 3343121.418124686},
            {x: -2562793.02666479,y: 4781331.562477808,z: 3343091.371430385}
        ], extrudedHeight:8, color:'#30C2FF', bordercolor:'#30C2FF', text:'林地'},
    {id:'村貌分布#03', status:1, etype:'area', type:6, hierarchy:[
        {x: -2562466.290727572,y: 4781504.741123917,z: 3343089.0017266944},
        {x: -2562473.890921232,y: 4781497.9591411725,z: 3343092.795036435},
        {x: -2562484.6554355323,y: 4781490.812746672,z: 3343095.099561092},
        {x: -2562493.6997328163,y: 4781486.756508668,z: 3343094.8460195404},
        {x: -2562503.15946505,y: 4781484.453492735,z: 3343090.304160976},
        {x: -2562513.016871116,y: 4781500.106597338,z: 3343060.2769948193},
        {x: -2562517.874737114,y: 4781500.938180509,z: 3343055.0615359005},
        {x: -2562528.139365689,y: 4781500.696975566,z: 3343049.522684114},
        {x: -2562529.603341155,y: 4781502.8572145915,z: 3343045.15156229},
        {x: -2562524.5972184124,y: 4781512.775933178,z: 3343035.38687918},
        {x: -2562519.339328531,y: 4781515.073228051,z: 3343034.8440494835},
        {x: -2562508.248119222,y: 4781514.811725858,z: 3343042.74427709},
        {x: -2562500.178433279,y: 4781511.751027552,z: 3343053.046027617},
        {x: -2562467.735634812,y: 4781507.461332471,z: 3343083.8806095663}
        ], extrudedHeight:1, color:'#016DFF', bordercolor:'#016DFF', text:'水域'},
    {id:'村貌分布#04', status:1, etype:'area', type:6, hierarchy:[
        {x: -2562429.9176217257,y: 4781559.718779866,z: 3343034.269775317},
        {x: -2562410.9333197437,y: 4781572.104326502,z: 3343030.9379234957},
        {x: -2562397.044244722,y: 4781576.3441573335,z: 3343035.612184742},
        {x: -2562396.2497028904,y: 4781533.294544608,z: 3343100.0493600345},
        {x: -2562441.331964008,y: 4781527.820122257,z: 3343075.4183264356},
        {x: -2562433.6104751364,y: 4781538.757530548,z: 3343063.6989077744},
        {x: -2562428.8093039114,y: 4781551.4594288515,z: 3343047.7405527174}
        ], extrudedHeight:4, color:'#0054C5', bordercolor:'#0054C5', text:'住宅用地'},
    {id:'村貌分布#05', status:1, etype:'area', type:6, hierarchy:[
        {x: -2562443.7559173675,y: 4781523.426652644,z: 3343080.2604107484},
        {x: -2562409.304428624,y: 4781526.966635112,z: 3343099.576627251},
        {x: -2562411.0860766997,y: 4781509.247866517,z: 3343123.3251447724},
        {x: -2562404.121044037,y: 4781517.400681227,z: 3343131.0577183017},
        {x: -2562400.2020066595,y: 4781492.476157483,z: 3343156.0045664767},
        {x: -2562427.155001829,y: 4781488.271159281,z: 3343144.9006552435},
        {x: -2562445.270670139,y: 4781473.167966397,z: 3343155.564565774},
        {x: -2562431.814705571,y: 4781462.68920632,z: 3343177.0813432233},
        {x: -2562445.509058827,y: 4781450.93090985,z: 3343183.799381128},
        {x: -2562450.169107841,y: 4781453.043958857,z: 3343178.5208096765},
        {x: -2562460.6285444824,y: 4781443.261360843,z: 3343183.1712012095},
        {x: -2562458.105527854,y: 4781439.977061248,z: 3343189.2046338688},
        {x: -2562476.7845961615,y: 4781432.419177279,z: 3343193.929398876},
        {x: -2562511.2786636692,y: 4781399.635979241,z: 3343203.5712415595},
        {x: -2562530.3291801945,y: 4781402.619406646,z: 3343185.236325329},
        {x: -2562541.4245889937,y: 4781393.478779629,z: 3343189.989903164},
        {x: -2562547.27095711,y: 4781397.028856921,z: 3343179.4864090383},
        {x: -2562490.2126522507,y: 4781448.936828891,z: 3343146.98937271},
        {x: -2562490.0762559897,y: 4781450.477269716,z: 3343145.3788816915},
        {x: -2562516.0217201295,y: 4781459.244821745,z: 3343116.9086475433},
        {x: -2562535.899291856,y: 4781445.922740688,z: 3343123.095286808},
        {x: -2562561.829680202,y: 4781454.0509445565,z: 3343088.2311026864},
        {x: -2562568.5258919327,y: 4781448.626469836,z: 3343089.0004528244},
        {x: -2562577.3150928607,y: 4781455.518349314,z: 3343072.78554685},
        {x: -2562579.130722343,y: 4781462.416018187,z: 3343068.8883742946},
        {x: -2562565.5652443073,y: 4781469.024790475,z: 3343064.0039677275},
        {x: -2562572.875108675,y: 4781483.358498092,z: 3343036.2730702623},
        {x: -2562568.739071789,y: 4781489.77549051,z: 3343030.731610019},
        {x: -2562549.885435048,y: 4781504.881437322,z: 3343025.3067154917},
        {x: -2562540.5748578836,y: 4781514.926949854,z: 3343019.277505513},
        {x: -2562531.945180298,y: 4781520.57380751,z: 3343018.931288936},
        {x: -2562524.9054911304,y: 4781520.245889109,z: 3343036.492424559},
        {x: -2562521.800486001,y: 4781520.391190801,z: 3343026.70652929},
        {x: -2562530.4018220706,y: 4781501.881392133,z: 3343044.4376390823},
        {x: -2562527.685878681,y: 4781499.519432674,z: 3343049.9744612044},
        {x: -2562516.6897988548,y: 4781494.997492748,z: 3343065.5950811915},
        {x: -2562508.776415497,y: 4781480.215836215,z: 3343092.7733839657},
        {x: -2562495.3731067968,y: 4781483.242129911,z: 3343098.450830053},
        {x: -2562484.3640183317,y: 4781487.971060816,z: 3343100.079570081},
        {x: -2562470.3363645347,y: 4781497.978037568,z: 3343096.2900263034},
        {x: -2562455.001578542,y: 4781511.269551792,z: 3343088.959009234}
        ], extrudedHeight:4, color:'#0054C5', bordercolor:'#0054C5', text:'住宅用地'},
    {id:'村貌分布#06', status:1, etype:'area', type:6, hierarchy:[
        {x: -2562489.781374956,y: 4781525.701782194,z: 3343041.9643321964},
        {x: -2562480.5407148483,y: 4781555.933410429,z: 3343005.046776785},
        {x: -2562486.2287752684,y: 4781561.608401145,z: 3342992.863546428},
        {x: -2562484.115965665,y: 4781575.398745902,z: 3342973.5706792106},
        {x: -2562467.3234256245,y: 4781593.158692519,z: 3342961.717784425},
        {x: -2562469.9304396063,y: 4781624.060644177,z: 3342921.529644867},
        {x: -2562475.2777663595,y: 4781626.300726249,z: 3342913.5401102193},
        {x: -2562502.0976654636,y: 4781610.871076581,z: 3342913.9324799445},
        {x: -2562574.083108188,y: 4781573.89794009,z: 3342908.226747502},
        {x: -2562570.8311688504,y: 4781562.60320415,z: 3342928.8422055715},
        {x: -2562583.6720832475,y: 4781551.315593538,z: 3342934.2648332557},
        {x: -2562586.3764545918,y: 4781542.600282643,z: 3342943.504299381},
        {x: -2562566.058263462,y: 4781539.216458415,z: 3342966.6003675875},
        {x: -2562550.9266321226,y: 4781532.678825241,z: 3342987.5615925845},
        {x: -2562540.655281489,y: 4781526.827037235,z: 3343003.6332907844},
        {x: -2562530.5836508716,y: 4781524.585896894,z: 3343014.1540123024}
        ], extrudedHeight:4, color:'#0054C5', bordercolor:'#0054C5', text:'住宅用地'},
    {id:'村貌分布#07', status:1, etype:'area', type:6, hierarchy:[
        {x: -2562638.5364084975,y: 4781548.84778072,z: 3342885.3654764905},
        {x: -2562608.9628806305,y: 4781547.399954066,z: 3342915.631109021},
        {x: -2562577.352032418,y: 4781582.268360547,z: 3342890.663364688},
        {x: -2562572.5034694397,y: 4781587.350572654,z: 3342887.2441322096},
        {x: -2562578.399754119,y: 4781596.634807746,z: 3342866.960434711},
        {x: -2562595.371660423,y: 4781597.889626234,z: 3342848.25235952}
        ], extrudedHeight:4, color:'#30FFD6', bordercolor:'#30FFD6', text:'教育用地'},
    {id:'村貌分布#08', status:1, etype:'area', type:6, hierarchy:[
        {x: -2562582.914300605,y: 4781612.660417538,z: 3342833.4153768183},
        {x: -2562576.1108650737,y: 4781626.815346251,z: 3342818.6499976404},
        {x: -2562578.3815822154,y: 4781637.573309642,z: 3342801.2476903247},
        {x: -2562587.1608589673,y: 4781641.256955911,z: 3342789.345707091},
        {x: -2562611.4904710213,y: 4781615.859560763,z: 3342806.686404397},
        {x: -2562672.0142781804,y: 4781576.559130008,z: 3342817.0409426168},
        {x: -2562689.832569799,y: 4781563.577365589,z: 3342822.142788777},
        {x: -2562640.452395369,y: 4781555.429156816,z: 3342871.658988954}
        ], extrudedHeight:4, color:'#0CB424', bordercolor:'#0CB424', text:'工业用地'},
]

// 迁徙路径数据
CesiumData.FlylineData = [
    {id:'党建引领01', points:[118.1879723,31.8155411,118.1882028,31.8160381]},
    {id:'党建引领02', points:[118.1879723,31.8155411,118.1880216,31.8166740]},
    {id:'党建引领03', points:[118.1879723,31.8155411,118.1874132,31.8164688]},
    {id:'党建引领04', points:[118.1879723,31.8155411,118.1872998,31.8149802]},
    {id:'党建引领05', points:[118.1879723,31.8155411,118.1883917,31.8145059]},
]

// 人员轨迹 2 4 5 8
CesiumData.LocationLine = [
    {x: -2562579.4328713235,y: 4781527.738367812,z: 3342969.7857458675},
    {x: -2562568.2679420006,y: 4781538.54485528,z: 3342967.2572369836},
    {x: -2562542.3398136613,y: 4781524.975241362,z: 3343006.463507039},
    {x: -2562518.3911489025,y: 4781523.479009354,z: 3343026.3835145063},
    {x: -2562440.346790165,y: 4781526.0810674215,z: 3343080.3986748355},
    {x: -2562395.6744510913,y: 4781533.157162181,z: 3343102.4776399448},
    {x: -2562397.5266082785,y: 4781576.922695844,z: 3343035.0505803516},
    {x: -2562410.894419809,y: 4781572.817838313,z: 3343031.4240366276},
    {x: -2562430.399033459,y: 4781559.917243567,z: 3343034.443571441},
    {x: -2562435.1303808177,y: 4781538.9960735,z: 3343062.958636372},
]

CesiumData.LocationPoint = [
    {id:'USERTIME#01', etype:'point', status:1, type:5, img:'icon-time01', Cartesian3:{x: -2562568.2679420006,y: 4781537.52485528,z: 3342967.2572369836}, text:'时间-1'},
    {id:'USERTIME#02', etype:'point', status:1, type:5, img:'icon-time02', Cartesian3:{x: -2562518.3911489025,y: 4781522.479009354,z: 3343026.3835145063}, text:'时间-2'},
    {id:'USERTIME#03', etype:'point', status:1, type:5, img:'icon-time03', Cartesian3:{x: -2562440.346790165,y: 4781525.0810674215,z: 3343080.3986748355}, text:'时间-3'},
    {id:'USERTIME#04', etype:'point', status:1, type:5, img:'icon-time04', Cartesian3:{x: -2562410.894419809,y: 4781571.817838313,z: 3343031.4240366276}, text:'时间-4'},
]

export default CesiumData;