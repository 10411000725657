let cesiumTool = {
    
};

// 新增点位数据
cesiumTool.CreatePoint = (Cesium, viewer, worldPosition) => {
    var point = viewer.entities.add({
        position: worldPosition,
        point: {
            color: Cesium.Color.BLUE,
            pixelSize: 10,
        }
    });
    return point;
}

// 捕获当前场景信息
cesiumTool.LogViewMessage = (Cesium, viewer, movement) => {
    return new Promise((resolve, reject) => {
        var scene = viewer.scene;
        var ellipsoid = scene.globe.ellipsoid;
        var cartesian = viewer.scene.pickPosition(movement.position);
        if (cartesian){
            var cartographic = ellipsoid.cartesianToCartographic(cartesian);
            var lon = Cesium.Math.toDegrees(cartographic.longitude).toFixed(7);
            var lat = Cesium.Math.toDegrees(cartographic.latitude).toFixed(7);
            // 地理高度
            var alt = (cartographic.height+1).toFixed(2);
            // 相机高度
            var height = viewer.camera.positionCartographic.height.toFixed(0);
            // 围绕Z轴旋转
            var heading = Cesium.Math.toDegrees(viewer.camera.heading).toFixed(2);
            // 围绕Y轴旋转
            var pitch = Cesium.Math.toDegrees(viewer.camera.pitch).toFixed(2);
            // 围绕X轴旋转
            var roll = Cesium.Math.toDegrees(viewer.camera.roll).toFixed(2);
            // 整理数据
            let res = {
                lon: lon,
                lat: lat,
                alt: alt,
                heading: heading,
                pitch: pitch,
                roll: roll
            }
            resolve(res)
        }else{
            reject('cartesian error');
        }
    })
}

// 根据图片和文字绘制canvas  ratio参数 是放大倍数
cesiumTool.DrawCanvas = function(img, text, ratio) {
    // 创建canvas标签
    var canvas = document.createElement('canvas'); 
    var ctx = canvas.getContext('2d');
    var width = ctx.measureText(text).width + 8,
    height = 20; //高度我这里是定死的，可以作为参数参入
    canvas.style.opacity = 1;
    canvas.width = width * ratio;
    canvas.height = height * ratio;
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';
    // 将画布缩放,将图像放大ratio倍画到画布上,目的使图片文字更加清晰
    ctx.scale(ratio, ratio);
    var image = new Image();
    image.src = img;
    // 图片创建是异步操作，需要在图片完成之后，再写入文字，能保证文字在图片上方。
    // 如果不在里面，会出现图片覆盖文字
    image.onload = function () {
        ctx.drawImage(image, 0, 0, width, height);
        // 名称文字
        ctx.fillStyle = '#ff0000';
        ctx.font = '8px 宋体';
        ctx.fillText(text, 8, height / 2 + 2);
    };
    return canvas;
}

export default cesiumTool;