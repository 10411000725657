<template>
    <div class="sjzl">
        <div class="top">
            <span>{{eventInfo.name}}</span>
            <img class="top-close" @click="closeModel" src="../../assets/images/close-icon.png" ondragstart="return false;"/>
        </div>
        <div class="info">
            <span>事件时间：{{eventInfo.time}}</span><br/>
            <span>处理结果：{{eventInfo.result}}</span>
        </div>
        <div class="imgs">
            <img class="img-item"  src="../../assets/images/safe/event-img.png" ondragstart="return false;"/>
            <img class="img-item"  src="../../assets/images/safe/event-img.png" ondragstart="return false;"/>
        </div>
        <div class="group">
            <div class="group-blue" @click="handlerEvent('deal')">{{eventInfo.cancel}}</div>
            <div class="group-red" @click="handlerEvent('alarm')">{{eventInfo.ok}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        eventInfo: {
            type: Object,
            default: ()=>{
                return {
                    name: '----',
                    time: '----/--/-- --:--:--',
                    result: '----',
                    cancel: '----',
                    cancelText: '----------',
                    ok: '----',
                    okText: '------------'
                }
            }
        }
    },
    data(){
        return{

        }
    },
    methods:{
        closeModel(){
            this.$emit('close')
        },
        handlerEvent(type){
            if(type == 'deal'){
                this.$message({
                    type: 'success',
                    message: this.eventInfo.cancelText,
                    offset: 80,
                    duration: 1000
                });
            }else{
                this.$message({
                    type: 'success',
                    message: this.eventInfo.okText,
                    offset: 80,
                    duration: 1000
                });
            }
            this.$emit('close')
        }
    }
}
</script>

<style lang="less" scoped>
    .sjzl{
        width: 278px;
        height: 236px;
        background: rgba(0, 0, 0, 0.8);
        .top{
            width: 100%;
            height: 32px;
            background: linear-gradient(to right, #A11E20, rgba(161, 30, 32, 0.1));
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0px 12px 0px 15px;
            box-sizing: border-box;
            line-height: 32px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .top-close{
                width: 18px;
                height: 18px;
                object-fit: contain;
                cursor: pointer;
                &:hover{
                    opacity: 0.6;
                }
            }
        }
        .info{
            width: calc(100% - 28px);
            margin: 12px auto;
            text-align: left;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(232, 250, 255, 0.8);
            line-height: 24px;
        }
        .imgs{
            width: calc(100% - 28px);
            height: 72px;
            margin: 0px auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .img-item{
                width: 122px;
                height: 100%;
                object-fit: cover;
            }
        }
        .group{
            width: calc(100% - 28px);
            height: 32px;
            margin: 15px auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .group-blue{
                width: 112px;
                height: 32px;
                background: rgba(8,55,126,0.5000);
                border: 1px solid rgba(62, 139, 180, 0.3);
                box-sizing: border-box;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FEFEFE;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all 0.15s;
                -webkit-transition: all 0.15s;
                &:hover{
                    opacity: 0.8;
                    transition: all 0.15s;
                    -webkit-transition: all 0.15s;
                }
            }
            .group-red{
                width: 112px;
                height: 32px;
                background: rgba(169,44,48,0.5000);
                border: 1px solid rgba(213, 51, 58, 0.3);
                box-sizing: border-box;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FEFEFE;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all 0.15s;
                -webkit-transition: all 0.15s;
                &:hover{
                    opacity: 0.8;
                    transition: all 0.15s;
                    -webkit-transition: all 0.15s;
                }
            }
        }
    }
</style>