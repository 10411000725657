<template>
    <div class="bot">
        <img class="bg-bot" src="../assets/images/situation/bg-bottom.png" ondragstart="return false;"/>
        <div class="bot-menu">
            <div class="menu-item" @click="changeMenu('综合态势')">
                <img v-if="activeMenu=='综合态势'" class="item-icon" src="../assets/images/situation/icon-zhts-hl.png" ondragstart="return false;"/>
                <img v-else class="item-icon" src="../assets/images/situation/icon-zhts.png" ondragstart="return false;"/>
                <div :class="activeMenu=='综合态势'?'item-labhl':'item-lab'">综合态势</div>
            </div>
            <div class="menu-item" @click="changeMenu('平安乡村')">
                <img v-if="activeMenu=='平安乡村'" class="item-icon" src="../assets/images/situation/icon-paxc-hl.png" ondragstart="return false;"/>
                <img v-else class="item-icon" src="../assets/images/situation/icon-paxc.png" ondragstart="return false;"/>
                <div :class="activeMenu=='平安乡村'?'item-labhl':'item-lab'">平安乡村</div>
            </div>
            <div class="menu-item" @click="changeMenu('乡村振兴')">
                <img v-if="activeMenu=='乡村振兴'" class="item-icon" src="../assets/images/situation/icon-xczx-hl.png" ondragstart="return false;"/>
                <img v-else class="item-icon" src="../assets/images/situation/icon-xczx.png" ondragstart="return false;"/>
                <div :class="activeMenu=='乡村振兴'?'item-labhl':'item-lab'">乡村振兴</div>
            </div>
        </div>
    </div>
</template>

// <div class="menu-item" @click="changeMenu('在线旅游')">
//     <img v-if="activeMenu=='在线旅游'" class="item-icon" src="../assets/images/situation/icon-zxly-hl.png" ondragstart="return false;"/>
//     <img v-else class="item-icon" src="../assets/images/situation/icon-zxly.png" ondragstart="return false;"/>
//     <div :class="activeMenu=='在线旅游'?'item-labhl':'item-lab'">在线旅游</div>
// </div>

<script>
export default {
    data(){
        return{
            activeMenu: '综合态势'
        }
    },
    mounted(){
        
    },
    methods:{
        /* 切换菜单 */
        changeMenu(val){
            this.activeMenu = val
            this.$emit('switchMenu', val)
        }
    }
}
</script>

<style lang="less" scoped>
    .bot{
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        .bg-bot{
            width: 100%;
            max-height: 40px;
            object-fit: fill;
            position: absolute;
            bottom: 0;
            z-index: 0;
        }
        .bot-menu{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: center;
            .menu-item{
                width: 120px;
                margin-bottom: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                cursor: pointer;
                &:hover{
                    .item-lab{
                        transition: all 0.15s;
                        -webkit-transition: all 0.15s;
                        font-size: 16px;
                        color: #FEFEFE;
                    }
                }
                .item-icon{
                    width: 78px;
                    height: 78px;
                    object-fit: contain;
                }
                .item-lab{
                    height: 20px;
                    line-height: 20px;
                    font-size: 15px;
                    font-family: Microsoft YaHei;
                    font-weight: 500;
                    color: #A2DEF5;
                    margin-top: 8px;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                }
                .item-labhl{
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 500;
                    color: #FEFEFE;
                    margin-top: 8px;
                }
            }
        }
    }
</style>