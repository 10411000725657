<template>
    <div class="top-menu">
        <div class="menu-item" @click="chooseMenu('企业公司')">
            <div class="item-box">
                <img v-if="activeMenu=='企业公司'" class="item-icon" src="../assets/images/situation/map-cricle-hl.png" ondragstart="return false;"/>
                <img v-else class="item-icon" src="../assets/images/situation/map-cricle.png" ondragstart="return false;"/>
                <img class="item-mini" src="../assets/images/situation/map-mini-qygs.png" ondragstart="return false;"/>
            </div>
            <div class="item-lab" :class="activeMenu=='企业公司'?'lab-active':'lab-dark'">企业公司</div>
        </div>
        <div class="menu-item" @click="chooseMenu('医疗诊所')">
            <div class="item-box">
                <img v-if="activeMenu=='医疗诊所'" class="item-icon" src="../assets/images/situation/map-cricle-hl.png" ondragstart="return false;"/>
                <img v-else class="item-icon" src="../assets/images/situation/map-cricle.png" ondragstart="return false;"/>
                <img class="item-mini" src="../assets/images/situation/map-mini-ylzs.png" ondragstart="return false;"/>
            </div>
            <div class="item-lab" :class="activeMenu=='医疗诊所'?'lab-active':'lab-dark'">医疗诊所</div>
        </div>
        <div class="menu-item" @click="chooseMenu('自建房')">
            <div class="item-box">
                <img v-if="activeMenu=='自建房'" class="item-icon" src="../assets/images/situation/map-cricle-hl.png" ondragstart="return false;"/>
                <img v-else class="item-icon" src="../assets/images/situation/map-cricle.png" ondragstart="return false;"/>
                <img class="item-mini" src="../assets/images/situation/map-mini-zjf.png" ondragstart="return false;"/>
            </div>
            <div class="item-lab" :class="activeMenu=='自建房'?'lab-active':'lab-dark'">自建房</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            activeMenu: '-1'
        }
    },
    methods:{
        chooseMenu(val){
            this.activeMenu = val
            this.$emit('chooseMenu',val)
        },
    }
}
</script>

<style lang="less" scoped>
    .top-menu{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 90px;
        z-index: 100;
        .menu-item{
            width: 120px;
            height: 84px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            &:hover{
                .lab-dark{
                    background: rgba(28,68,1,0.6000);
                    font-weight: 500;
                    transition: all 0.15s;
                    -webkit-transition: all 0.15s;
                }
            }
            .item-box{
                width: 54px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                .item-icon{
                    width: 54px;
                    height: 54px;
                    object-fit: contain;
                }
                .item-mini{
                    width: 16px;
                    height: 16px;
                    object-fit: contain;
                    position: absolute;
                    z-index: 1;
                }
            }
            .item-lab{
                width: 85px;
                height: 26px;
                border-radius: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
            .lab-dark{
                background: rgba(0,0,0,0.4000);
                transition: all 0.15s;
                -webkit-transition: all 0.15s;
            }
            .lab-active{
                background: rgba(28,68,1,0.6000);
                font-weight: 500;
                transition: all 0.15s;
                -webkit-transition: all 0.15s;
            }
        }
    }
</style>